/* eslint-disable react/no-unescaped-entities */
import './App.css';
import React from 'react';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          there's nothing here at the moment.
        </p>
        <p>☁</p>
      </header>
    </div>
  );
}

export default App;
